<template>
  <div class="Buyout width-100">
    <div class="Buyout__container">
      <!-- TOP -->
      <div class="Buyout__header">
        <div class="row mb-21">
          <label class="fs-22 fw-med">Buyouts</label>
        </div>

        <!-- CLIENT PICKER + FILE UPLOADER + PAYOUT BUTTON -->
        <div class="row row--justify-between mb-25">
          <div class="row">
            <base-client-search
              @selected="client = $event"
              class="Buyout__client-search mr-8"
              :instructions="'Must select a client before uploading buyouts'"
              :placeholder="'Choose a client'"
              :valid="!!client"
            />
            <base-button
              @click="triggerFileUpload"
              class="bg-green fc-white"
              :disabled="!client"
            >
              Upload Spreadsheet
            </base-button>
          </div>
          <input
            v-show="false"
            @change="uploadExcelSheet($event.target.files[0])"
            accept=".csv"
            class="Buyout__file-upload-input"
            ref="Buyout__file-upload-input"
            type="file"
          >
          <base-button
            @click="approveAllInvoices"
            class="bg-blue fc-white mb-8 ml-auto"
            :disabled="buttonDisabled"
          >
            <i class="fa fa-add-circle" />
            Pay Buyouts
          </base-button>
        </div>

        <!-- TOTAL NUMBER AND $ AMOUNT OF BUYOUTS TO BE APPROVED -->
        <div class="row mb-25">
          <label
            v-if="countWithInvoice"
            class="fc-light fs-14"
          >
            {{ countWithInvoice }} Total
            ({{
              Intl
                .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format(totalWithInvoice / 100)
            }})
          </label>
          <label
            v-else
            class="fc-light fs-14"
          >
            There are no buyouts ready to be approved
          </label>
        </div>
      </div>

      <!-- TABLE HEADER-->
      <div class="Buyout__head-wrapper row row--align-center">
        <label class="Buyout__head-lbl fc-light fs-12 fw-medium uppercase">
          Load Number
        </label>
        <label class="Buyout__head-lbl fc-light fs-12 fw-medium uppercase">
          Date
        </label>
        <label class="Buyout__head-lbl fc-light fs-12 fw-medium uppercase">
          Rate
        </label>
        <label class="Buyout__head-lbl fc-light fs-12 fw-medium uppercase">
          Debtor name (Debtor)
        </label>
        <label class="Buyout__head-lbl right">
          <base-button
            v-if="searchResults.length"
            @click="deleteAllBuyouts"
            class="bg-green fc-white"
            :disabled="buttonDisabled"
          >
            Delete All
          </base-button>
        </label>
      </div>

      <!-- TABLE DATA -->
      <transition-group name="Transition__fade">
        <base-buyout-item
          v-for="(result, index) of searchResults"
          :key="index"
          @approved="getBuyouts"
          @created="getBuyouts"
          @deleted="getBuyouts"
          :buyout="result"
        />
      </transition-group>
    </div>

    <base-pagination
      @paginate="paginate"
      class="mb-25 mt-25"
      :item-count="pagination.count"
      :page="pagination.page"
    />
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */

// Packages
import Papa from 'papaparse'
// Helpers
import {
  Buyouts
} from '../utils/api'
// Components
import BaseButton from '../components/base-button.vue'
import BaseBuyoutItem from '../components/base-buyout-item.vue'
import BaseClientSearch from '../components/base-client-search.vue'
import BasePagination from '../components/base-pagination.vue'

export default {
  name: 'Buyout',

  components: {
    BaseButton,
    BaseBuyoutItem,
    BaseClientSearch,
    BasePagination,
  },

  async created () {
    // Check for page in query
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)

    if (queryString) {
      if (queryParams.has('page')) {
        if (queryParams.get('page') !== this.pagination.page) {
          this.$set(this.pagination, 'page', Number(queryParams.get('page')))
        }
      }
    }

    await this.getBuyouts()
  },

  data () {
    return {
      buttonDisabled: false,
      client: null,
      countWithInvoice: 0,
      pagination: {
        page: 1,
        count: 0
      },
      searchResults: [],
      showCreate: [],
      totalWithInvoice: 0,
    }
  },

  methods: {
    async approveAllInvoices () {
      this.progressStart()
      this.buttonDisabled = true

      try {
        // eslint-disable-next-line no-restricted-syntax
        await Buyouts.approveAllInvoices();
      }
      catch (error) {
        this.requestFailure({ message: 'There was an issue approving the the pending buyout items' })
        this.captureSentryEvent(
          'Buyouts "Approve All Approve" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error approving invoice(s):', error)
      }

      try {
        this.resetPagination()
        await this.getBuyouts()
        this.progressFinish()
      }
      catch (error) {
        this.requestFailure({ message: 'There was an issue paying buyouts' })
        this.captureSentryEvent(
          'Buyouts "Pay Buyouts" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error paying buyouts:', error)
      }

      this.buttonDisabled = false
    },

    async deleteAllBuyouts () {
      this.progressStart()
      this.buttonDisabled = true

      try {
        await Buyouts.deleteAll()
      }
      catch (error) {
        this.requestFailure({ message: 'There was an issue deleting the buyouts' })
        this.captureSentryEvent(
          'Buyouts "Delete All" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error deleting buyouts:', error)
      }

      this.resetPagination()
      await this.getBuyouts()
      this.buttonDisabled = false
      this.progressFinish()
    },

    async getBuyouts () {
      this.progressStart()

      try {
        const results = (await Buyouts.queryList({
          include: 'pendingInvoices',
          offset: (this.pagination.page - 1) * 25,
        }, 25)).data
        this.countWithInvoice = results.countWithInvoice
        this.$set(this.pagination, 'count', results.count)
        this.searchResults = results.rows
        this.totalWithInvoice = results.totalWithInvoice
        // Update URL with current page searched with
        window.history.replaceState({}, '', `?page=${this.pagination.page}`)
        this.progressFinish()
      }
      catch (error) {
        this.requestFailure({ message: 'There was an issue getting buyout invoices' })
        this.captureSentryEvent(
          'Buyouts "GET Buyouts" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error getting buyouts:', error)
      }
    },

    async paginate (page) {
      this.$set(this.pagination, 'page', page)
      await this.getBuyouts()
      window.scrollTo(0, 0)
    },

    resetPagination () {
      this.$set(this.pagination, 'page', 1)
    },

    parseSpreadsheet (file) {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (results) => {
            const rows = results.data.map(result => {
              return {
                buyout_invoice_date: result['buyout date'],
                client_id: this.client.id,
                debtor_name: result['debtor name'],
                load_number: result['load number'],
                mc: this.client.mc,
                rate: result.rate * 100,
              }
            })
            resolve(rows)
          },
          error: (error) => {
            console.log(error)
            this.captureSentryEvent(
              'Upload Excel Sheet "Parse" Error',
              {
                config: error.config,
                data: this.$data,
                details: error,
                props: this.$props,
                response: error.response,
              }
            )
            reject(error)
          }
        })
      })
    },

    triggerFileUpload () {
      const input = this.$refs['Buyout__file-upload-input']
      input.click()
    },

    async uploadExcelSheet (file) {
      // file.name, file.size, file.type, file.lastModified
      const buyouts = await this.parseSpreadsheet(file)
      await Buyouts.postBatchCreate({ batch: buyouts })
      this.resetPagination()
      await this.getBuyouts()
    },
  },
}
</script>

<style lang="sass">
@import '../styles/tables'

.Buyout
  background-color: $white
  margin-top: rem(52px)

  &__client-search
    width: rem(250px)

  &__container
    border-radius: $border-radius
    box-shadow: $container-shadow


  &__file-upload-input

  &__head-lbl
    flex: 1 0 0
    padding-right: 1rem

    &:last-child
      padding-right: 0

  &__head-wrapper
    padding: rem(10px) rem(27px)

  &__header
    border-bottom: $border
    padding: rem(41px) rem(29px) 0 rem(29px)
</style>