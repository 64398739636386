var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseInvoiceItem" },
    [
      _c(
        "div",
        {
          staticClass:
            "BaseInvoiceItem__top-container BaseInvoiceItem__top-container--buyout row"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "BaseInvoiceItem__top-container--desktop row row--align-center"
            },
            [
              _c("div", { staticClass: "column" }, [
                _c(
                  "label",
                  {
                    class: [
                      "fs-14 fw-med",
                      {
                        clickable: _vm.dataBuyout.id
                      }
                    ],
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.goToInvoiceDetail($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.dataBuyout.load_number) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "fs-14" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("datetime")(
                          _vm.dataBuyout.original_buyout_invoice_date,
                          "MM/DD/YYYY"
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "fs-14" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(_vm.dataBuyout.amounts.primary_rate)
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "fs-14 uppercase" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.dataBuyout.debtor_selected_name
                          ? _vm.sanitizeAlgoliaNames(
                              _vm.dataBuyout.debtor_selected_name
                            )
                          : null
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "row row--justify-end" },
                  [
                    _c(
                      "base-button",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.bottomExpanded = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.dataBuyout.id ? "Edit" : "Create") +
                            "\n          "
                        )
                      ]
                    ),
                    _c(
                      "base-button",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.deleteBuyout($event)
                          }
                        }
                      },
                      [_vm._v("\n            Delete\n          ")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "transition",
        { attrs: { name: "Transition__fade" } },
        [
          _vm.bottomExpanded
            ? _c("base-invoice-create", {
                staticClass: "InvoicesSearch__InvoiceCreate mb-16",
                attrs: {
                  invoice: _vm.dataBuyout,
                  "from-buyout": true,
                  "user-is-client": false
                },
                on: {
                  cancel: function($event) {
                    _vm.bottomExpanded = false
                  },
                  created: _vm.createInvoice,
                  updated: _vm.createInvoice
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }