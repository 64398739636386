<template>
  <div class="BaseInvoiceItem">
    <!-- BUYOUT ITEMS -->
    <div class="BaseInvoiceItem__top-container BaseInvoiceItem__top-container--buyout row">
      <div class="BaseInvoiceItem__top-container--desktop row row--align-center">
        <!-- LOAD NUMBER -->
        <div class="column">
          <label
            @click.stop="goToInvoiceDetail"
            :class="['fs-14 fw-med', {
              'clickable': dataBuyout.id,
            }]"
          >
            {{ dataBuyout.load_number }}
          </label>
        </div>
        <!-- BUYOUT INVOICE DATE -->
        <div class="column">
          <label class="fs-14">
            {{ dataBuyout.original_buyout_invoice_date | datetime('MM/DD/YYYY') }}
          </label>
        </div>
        <!-- RATE -->
        <div class="column">
          <label class="fs-14">
            {{
              new Intl
                .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format(dataBuyout.amounts.primary_rate)
            }}
          </label>
        </div>
        <!-- DEBTOR NAME (DBETOR) -->
        <div class="column">
          <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
          <label class="fs-14 uppercase">
            <!-- If dataBuyout has a system debtor, display; if not, display spreadsheet name -->
            {{
              dataBuyout.debtor_selected_name
                ? sanitizeAlgoliaNames(dataBuyout.debtor_selected_name)
                : null
            }}
          </label>
        </div>
        <!-- BUTTONS -->
        <div class="column">
          <div class="row row--justify-end">
            <!-- SHOW IF BUYOUT DOES NOT HAVE AN INVOICE_ID -->
            <base-button @click.stop="bottomExpanded = true">
              {{ dataBuyout.id ? 'Edit' : 'Create' }}
            </base-button>
            <base-button @click.stop="deleteBuyout">
              Delete
            </base-button>
          </div>
        </div>
      </div>
    </div>

    <transition name="Transition__fade">
      <base-invoice-create
        v-if="bottomExpanded"
        @cancel="bottomExpanded = false"
        @created="createInvoice"
        @updated="createInvoice"
        class="InvoicesSearch__InvoiceCreate mb-16"
        :invoice="dataBuyout"
        :from-buyout="true"
        :user-is-client="false"
      />
    </transition>
  </div>
</template>

<script>
// Helpers
import {
  sanitizeAlgoliaNames,
  transformRawInvoice,
} from '../utils/helpers'
import {
  Buyouts,
  Invoice,
  Debtor
} from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseInvoiceCreate from './base-invoice-create.vue'

export default {
  name: 'BaseInvoiceItem',

  components: {
    BaseButton,
    BaseInvoiceCreate,
  },

  props: {
    buyout: {
      type: Object,
      required: true,
    },
  },

  created () {
    this.transformBuyout()
  },

  data () {
    return {
      bottomExpanded: false,
      dataBuyout: null,
    }
  },

  watch: {
    buyout () {
      this.transformBuyout()
    }
  },

  methods: {
    async createInvoice (createdInvoice) {
      this.progressStart()

      try {
        await Buyouts.update({
          ...this.buyout,
          debtor_name: createdInvoice.debtor_selected_name,
          debtor_id: createdInvoice.debtor_id,
          client_id: createdInvoice.client_id,
          invoice_id: createdInvoice.id,
          // Evidently, were not calling this total_amount on the backend like invoices...
          rate: Number(createdInvoice.primary_rate)
            + Number(createdInvoice.lumper)
            + Number(createdInvoice.detention)
            - Number(createdInvoice.advance),
        })
        this.progressFinish()
        this.bottomExpanded = false
        this.$emit('created')
      }
      catch (error) {
        this.requestFailure({ message: 'There was an issue creating an invoice from the buyout' })
        this.captureSentryEvent(
          'Buyout Item "Create" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error creating invoice:', error)
      }
    },

    async deleteBuyout () {
      this.progressStart();

      try {
        await Buyouts.delete(this.dataBuyout.buyout_id)
        // The invoice of a deleted buyout should be updated to be declined
        if (this.dataBuyout.id) {
          const invoice = (await Invoice.get(this.dataBuyout.id)).data
          await Invoice.update(Object.assign(invoice, { status: 'declined' }))
        }
        this.progressFinish()
        // Update buyouts on buyouts.vue
        this.$emit('deleted')
      }
      catch (error) {
        this.requestFailure({ message: 'There was an issue deleting the buyout' })
        this.captureSentryEvent(
          'Buyout Item "Delete" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error deleting invoice:', error)
      }
    },

    // Remove <em></em> from debtor name
    // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1694
    sanitizeAlgoliaNames,

    goToInvoiceDetail () {
      if (!this.dataBuyout.id) return
      this.$router.push({
        name: 'invoice-details',
        params: {
          id: this.dataBuyout.id.toString(),
        }
      })
    },

    /* Why we transform buyouts
    * 1. The BE, as usual, did not ask how the FE would like them, resulting in a very unfriendly
    *    structure
    * 2. When a buyout is first uploaded, it may not have all the data it needs to become an invoice,
    *    but the BE formats the non-invoice portion of the buyout as a separate entity from the
    *    invoice created from the buyout item, which ends up giving you a structure:
    *    {
    *      ...buyoutSpecificDetails,
    *      invoice: {...},
    *    }
    *    Which makes it a bitch to update the UI and provide base-invoice-create what it needs to
    *    hydrate when we click "create" or "edit" from a non-invoice buyout item
    * 3. The BE...
    * 4. We can't rely on buyout.invoice for shit, because it's null until an invoice exists,
    *    which makes for a mess in the template checking with tertiaries for everything
    * 5. For some reason, a buyout has a `rate` value, while we have a `primary_rate` value
    *    that it 1-to-1 matches up with, but the names have to be different, I guess...
    */
    async transformBuyout() {
      // Transforms prop 'buyout' to data prop 'dataBuyout'
      // Don't taint transformRawInvoice with `invoice_id`
      this.dataBuyout = {
        ...transformRawInvoice(
          {
            ...this.buyout,
            client: {
              ...this.buyout.client,
              id: this.buyout.client_id,
              mc: this.buyout.mc,
            },
            debtor: this.buyout.debtor || { id: await Debtor.getUnknownDebtorId() },
            // app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639
            debtor_selected_name: this.buyout.debtor && this.buyout.debtor.name
              ? this.buyout.debtor.name
              : 'debtor not found',
            id: this.buyout.invoice_id,
            is_buyout: true,
            // buyout_invoice_date is a buyout-specific only date; it does not apply to invoice items
            // which we need because it's passed as a prop to the base-invoice-create component
            original_buyout_invoice_date: this.buyout.buyout_invoice_date,
            primary_rate: this.buyout.rate,
            // Overwrite any of the above information with an invoice
            // this.buyout.invoice is not `null` AFTER an invoice is created
            // which should be used in place of the starting information in the buyout item labels
            ...this.buyout.invoice,
          }
        ),
        buyout_id: this.buyout.id,
      }
      console.log(this.dataBuyout)
    },
  },
}
</script>

<style lang="sass">
@import '../styles/invoice-items.sass'
</style>